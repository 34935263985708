import { Api } from "@/api";
import axios from "axios";
import Store from "@/store";

const LoginDataService = {
    loginChecked: false,
    
  async tokenAuth(username, password, code, token) {
    const url = Api.BACKEND_URL + Api.TOKEN_AUTH;
    let postData;
    if (token) {
      postData = {
        "code": code,
        "token": token,
      };
    } else {
      postData = {
        "username": username,
        "password": password,
      };
    }
    try {
      return await axios.create({
        withCredentials: false,
        headers: {
          "Accept-Language": Store.getters.gLanguage,
        }
      }).post(url, postData);
    } catch (err) {
      console.error(err);
      return err.response;
    }
  },

  async requestMFAMail(mfaToken, mfaType) {
    try {
      const url = Api.BACKEND_URL + Api.REQUEST_MFA_MAIL;
      return await axios.create({
        withCredentials: false,
        headers: {
          "Accept-Language": Store.getters.gLanguage,
        }
      }).post(url, {token: mfaToken, type: mfaType});
    } catch (err) {
      return err.response;
    }
  },

  check() {
    Store.dispatch("setLoginChecked", true);
    return false;
    /* const uri = Api.BACKEND_URL + Api.CHECKLOGIN;
    axios
      .create({ withCredentials: true })
      .get(uri)
      .then(response => {
        if (response.data.success) {
          if (response.data.user && response.data.user.uid) {
            Store.dispatch("setLoginChecked", true);
            Store.dispatch("setLogin", true);
            Store.dispatch("setUser", response.data.user);
          }
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => (this.isLoading = false)); */
  },

  logout() {
    const postData = "logintype=logout";
    axios
      .post(Api.BACKEND_URL + Api.LOGIN, postData)
      .then(response => {
        //LoaderMainnav.get();
        this.testResponse = response;
        this.$store.dispatch("setLogin", false);
        this.$store.dispatch("setUser");
        setTimeout(() => {
          window.location = "/";
        }, 100);
      })
      .catch();
  }

};


export default LoginDataService;