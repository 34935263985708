import Vue from "vue";
import Router from "vue-router";
import Layout from "@/views/layout/";
import Store from "@/store";
import { ContentController } from "@/controller";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: {
          title: "Home",
          noCache: true,
          displayStepNavigation: true,
          displayBackButton: false
        }
      }
    ]
  },
  {
    path: "/datenschutz",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/datenschutz",
        component: () => import("@/views/gdpr/index"),
        name: "datenschutz",
        meta: {
          title: "Datenschutz",
          noCache: true,
          displayStepNavigation: false,
          displayBackButton: true
        }
      }
    ]
  }
];

const router = new Router({
  //mode: "history",
  base: process.env.BASE_URL,
  routes: constantRouterMap
});

router.beforeEach((to, from, next) => {
  const lang = to.query.lang;
  if (lang) {
    Store.dispatch("setLanguage", lang);
    ContentController.fetchData();
    const newQuery = { ...to.query };
    delete newQuery.lang;
    next({ path: to.path, query: newQuery, replace: true });
  } else {
    next();
  }
});

export default router;

/* export const defaultAsyncRouterMap = [
  {
    path: "/about-idm-configurator",
    component: Layout,
    redirect: "index",
    meta: {
      title: "About iDM Configurator",
      noCache: true
    },
    children: [
      {
        path: "/about-wispowo",
        component: () => import("@/views/home/index"),
        name: "about-idm-configurator",
        meta: {
          title: "About iDM Configurator",
          noCache: true
        }
      }
    ]
  },
  { path: "*", redirect: "/404", hidden: true }
]; */
