import { LoginDataService} from "@/dataservice";
import Store from "@/store";
import Cookie from "@/utils/Cookie";

//import { dataUser } from "@/data/";
import { ConfiguratorFormController } from "./";

const LoginController = {
    tokenCookieName: '_idm_configurator_token',
    loggedIn: false,
    token: "",

    user: {
        username: "",
        userRealname: ""
    },
    
    /** 
     * checkLogin
     * 
     * @return {Boolean} token exists
    */
     checkLogin() {            
        this.token = Cookie.readCookie(this.tokenCookieName);
        if (this.token) {
            this.loggedIn = true;
            Store.dispatch("setMode", "expert");
            this.user.username = "username";
            this.user.userRealname = "Logout";
        }
    },

    /** 
     * doLogin
     * 
     * @param {string} username
     * @param {string} password
     * 
     * @return {Boolean} log in success
    */
    async doLogin(username, password, code, token) {
        const response = await LoginDataService.tokenAuth(username, password, code, token);
        return this.handleLoginResponse(response);
    },

    handleLoginResponse(response) {
        let mfaRequired, mfaToken, mfaType;
        if (response.status === 200 && response.data.token) {
            this.token = response.data.token;
            this.loggedIn = true;
            mfaRequired = false;
            mfaToken = "";
            mfaType = "";
            Store.dispatch("setMode", "expert");
                //[WS 2022-12-19] TODO replace response if sent
            this.user.username = "username";
            this.user.userRealname = "Logout";
            Cookie.createCookie(this.tokenCookieName, response.data.token);
        } else if (response.status === 401 && response.data.token) {
            mfaRequired = true;
            this.loggedIn = false;
            mfaToken = response.data.token;
            mfaType = response.data.type;
        } else {
            mfaRequired = false;
            this.loggedIn = false;
            mfaToken = "";
            mfaType = "";
        }
        return [this.loggedIn, mfaRequired, mfaToken, mfaType];
    },

    async requestMFAMail(mfaToken, mfaType) {
        const response = await LoginDataService.requestMFAMail(mfaToken, mfaType);
        return response.status >= 200 && response.status < 300;
    },

    /** 
     * doLogout
     * 
     * @return {void}
    */    
    doLogout() {
        this.loggedIn = false;
        Store.dispatch("setMode", "customer");
        Store.dispatch("setConfiguratorFormMode", "basic");
        ConfiguratorFormController.setFormMode("basic");
        Cookie.eraseCookie(this.tokenCookieName);
        this.token = "";
        console.log('Cookie after logout',Cookie.readCookie(this.cookieName));
    }
};
  
export default LoginController;