export const Api = Object.freeze({
    BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    RESULT: "example_result.json",
    FORM_CUSTOMER: "/api/configurator_form/?format=json&expert=0",
    FORM_EXPERT: "/api/configurator_form/?format=json&expert=1",
    EXAMPLE_RESULT: "/example_result",
    POST_FORM_CUSTOMER: "/api/configurator_form/?expert=0",
    POST_FORM_EXPERT: "/api/configurator_form/?expert=1",
    TOKEN_AUTH: "/api/token-auth/",
    WEATHER_PREFETCH: "/api/weather_prefetch/",
    ORGANIZATION_SEARCH: "/api/organization_search/",
    USER_REQUEST: "/api/configurator_userrequest/",
    CONTENT: "/api/configurator_translations/",
    REQUEST_MFA_MAIL: "/api/mfa/request-mail/",
  });
  