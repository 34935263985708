<template>
    <div class="overlay" :class="{ 'is-active': active, 'is-visible': visible }">
        <div class="loginbox" :style="style" :class="{ 'is--active': active, 'is--visible': visible }" @click.self="handleWrapperClick">
            <button class="button--icon button--close" @click="handleCloseClick" >
                <i class="svgicon svgicon--close">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
                    </svg>
                </i>
            </button>
            <div class="loginbox__inner">
            <header class="loginbox__header">
                <h3>{{  getContent('CONF_TEXT_LOGIN_HEADLINE', true) }}</h3>
            </header>
            <form @submit.prevent="submit" novalidate class="form--login" @keyup="onFormKeyup">
                <div class="form-property form-property--error" v-if="isError">
                    <p class="form__error">{{  getContent('CONF_ERROR', true) }}</p>
                </div>
                <div class="form-property" v-if="!mfaRequired">
                    <label for="user">{{ getContent('CONF_TEXT_LOGIN_NAME', true) }}</label>
                    <input type="email" class="form-control" id="user" v-model.lazy.trim="form.user" @blur="onFieldBlur('user')" v-bind:class="getFieldClasses('user')" />
                </div>
                <div class="form-property" v-if="!mfaRequired">
                    <label for="pass">{{ getContent('CONF_TEXT_LOGIN_PASSWORD', true) }}</label>
                    <input type="password" class="form-control" id="pass" v-model.lazy.trim="form.pass" @blur="onFieldBlur('pass')" v-bind:class="getFieldClasses('pass')"/>
                </div>
                <div class="form-property" v-if="mfaRequired">
                    <label for="code">{{ getContent('CONF_TEXT_LOGIN_CODE', true) }}</label>
                    <input type="code" class="form-control" id="code" v-model.lazy.trim="form.code" @blur="onFieldBlur('code')" v-bind:class="getFieldClasses('code')"/>
                </div>
                <div class="form-property form-property--hidden">
                    <input type="hidden" class="form-control is--hidden" id="logintype" v-model.lazy.trim="form.logintype" />
                    <input type="hidden" class="form-control is--hidden" id="pid" v-model.lazy.trim="form.pid" />
                </div>
                <button type="button" v-if="mfaRequired" @click="requestMail" class="button button--dense">
                  {{  getContent('CONF_MFA_REQUEST_MAIL', true) }}
                </button>
                <div class="form-property form-property--action">
                    <a v-if="!mfaRequired" href="https://www.myidm.at/administration/user/forgot-password" target="_blank" class="button button--dense">
                      {{  getContent('CONF_TEXT_LOGIN_FORGOT_PASSWORD', true) }}
                    </a>
                    <div v-if="mfaRequired"></div>
                    <!-- <button class="button--dense" @click.prevent="onForgotPassword" :disabled="submitting">

                    </button> -->
                    <button v-if="!submitting" class="button--primary" type="submit" @click.prevent="onSubmit" :disabled="submitting">
                      {{  getContent('CONF_TEXT_LOGIN_BUTTON', true) }}
                    </button>
                    <span v-if="submitting" v-html="loaderSVG" class="form-loader"></span>
                </div>

                <!-- todo: remove comment
                <div style="display: flex; place-items: center; gap: 0.5rem; margin-top: 1rem;" v-if="!mfaRequired">
                  <div class="divider-surface divider-v-xs" style="width: 200px"></div>
                  <div>{{ getContent('CONF_LOGIN_OR', true) }}</div>
                  <div class="divider-surface divider-v-xs"  style="width: 200px"></div>
                </div>

                <div style="display: grid; gap: 0.5rem; margin-top: 1rem;" v-if="!mfaRequired">
                  <div class="google-button" @click="socialLogin('google')">
                    <div class="google-logo">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span style="color: black;">{{  getContent('CONF_LOG_IN_WITH_GOOGLE', true) }}</span>
                  </div>

                  <div class="apple-button" @click="socialLogin('apple')">
                    <div class="apple-logo">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 259">
                        <rect width="259" height="259" fill="#fff" rx="15"></rect>
                        <path d="M28.2 20.4c.9 0 1.9-.6 2.5-1.4a4 4 0 001-2.6V16c-1 0-2 .6-2.8 1.4-.5.7-1 1.6-1 2.6h.3zm-3 14.6c1.2 0 1.7-.8 3.2-.8 1.4 0 1.7.8 3 .8 1.2 0 2-1.2 2.8-2.4 1-1.3 1.3-2.6 1.3-2.7s-2.5-1-2.5-3.8c0-2.4 2-3.5 2-3.6a4.3 4.3 0 00-3.6-2c-1.4 0-2.6 1-3.3 1-.7 0-1.8-.9-3-.9-2.3 0-4.6 2-4.6 5.7 0 2.3.9 4.7 2 6.3.8 1.3 1.6 2.4 2.8 2.4z" transform="scale(10.79167) translate(-16 -14)"></path>
                      </svg>
                    </div>
                    <span style="color: black;">{{  getContent('CONF_LOG_IN_WITH_APPLE', true) }}</span>
                  </div>
                </div>
                -->
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
//import Store from "@/store";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
//import qs from "qs";
import { LoginController, ContentController } from "@/controller";

import { Api } from "@/api/";

export default {
  name: "LoginForm",
  data() {
    return {
      isError: false,
      mfaRequired: false,
      mfaToken: "",
      mfaType: "",
      errors: [],
      submitting: false,
      form: {
        user: "",
        pass: "",
        code: "",
        logintype: "login",
        pid: 112
      },
      visible: false,
      active: false,
      loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
    };
  },
  computed: {
    ...mapGetters(["gUser"]),
    style() {
      return this.active ? "display: block;" : "display: none";
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      this.submit();
    },
    onFormKeyup(ev) {
      this.isError = false;
      if (ev.key == "Enter" || ev.code == "Enter" || ev.keyCode==13) {
        this.submit();
      }
    },
    async requestMail() {
      this.isError = !(await LoginController.requestMFAMail(this.mfaToken, this.mfaType));
    },
    onForgotPassword() {
        console.log("onForgotpassword");
    },
    async submit() {
      this.submitting = true;
      const [loginSuccess, mfaRequired, mfaToken, mfaType] = await LoginController.doLogin(this.form.user, this.form.pass, this.form.code, this.mfaToken);
      this.submitting = false;
      this.handleLoginResponse(loginSuccess, mfaRequired, mfaToken, mfaType);
    },
    handleLoginResponse(loginSuccess, mfaRequired, mfaToken, mfaType) {
      const wasMfaRequired = this.mfaRequired;
      this.mfaRequired = mfaRequired;
      this.mfaToken = mfaToken;
      this.mfaType = mfaType;
      if (loginSuccess) {
        this.doClose();
      } else if (!mfaRequired || (mfaRequired && wasMfaRequired)) {
        this.isError = true;
      }
    },
    socialLogin(provider) {
      const encodedCallbackUrl = encodeURIComponent(`${window.location.origin}/`);
      const url = `${Api.BACKEND_URL}/login/${provider}/?callback_url=${encodedCallbackUrl}&response_mode=web_message`;
      const callback = async (event) => {
        if (event.origin !== "https://a.test.myidm.at")
          return;
        const loginUrl = Api.BACKEND_URL + Api.TOKEN_AUTH;
        const params = event.data;
        let response = null;
        this.submitting = true;
        try {
          response = await axios.post(loginUrl, params);
        } catch (error) {
          response = error.response;
        }
        this.submitting = false;
        const [loginSuccess, mfaRequired, mfaToken] = LoginController.handleLoginResponse(response);
        this.handleLoginResponse(loginSuccess, mfaRequired, mfaToken);
        window.removeEventListener("message", callback);
      }
      window.addEventListener("message", callback);

      const popupHeight = 600;
      const popupWidth = 500;
      const browserWidth = window.innerWidth;
      const browserHeight = window.innerHeight;
      const left = (browserWidth / 2) - (popupWidth / 2) + window.screenX;
      const top = (browserHeight / 2) - (popupHeight / 2) + window.screenY;
      window.open(url, 'Social Sign-In',`width=${popupWidth},height=${popupHeight},left=${left},top=${top},menubar=no,location=no,scrollbars=no,status=no`);
    },
    enableSubmitLoader() {
      this.submitting = true;
    },
    disableSubmitLoader() {
      this.submitting = false;
    },
    /* sendFormData() {
      this.enableSubmitLoader();
      const formData = qs.stringify(this.form, { parseArrays: false });
      axios
        .post(Api.BACKEND_URL + Api.LOGIN, formData)
        .then(response => {
          this.submitSuccess(response);
          this.disableSubmitLoader();
        })
        .catch(error => {
          //console.log(error);
          Store.dispatch("logout");
          this.submitError(error);
          this.disableSubmitLoader();
        });
    }, */
     /* submitSuccess(response) {
      console.log(response);
      if (response.data.success) {
        Store.dispatch("setLogin", true);
        Store.dispatch("setUser", response.data.user);
        this.isError = false;
        setTimeout(() => {
          window.location = "/";
        }, 100);
        this.doClose();
      } else {
        this.errorHeader = "error.invalidFields";
        this.errors = response.data.errors;
        this.isError = true;
        Store.dispatch("logout");
      }
      this.form.pass = "";
    }, */
    /* submitError() {
      this.errorHeader = "error.general";
      this.errors = [{ field: null, message: "error.generalMessage" }];
      this.isError = true;
    }, */
    /* validationError() {
      this.errorHeader = "error.invalidFields";
      this.errors = this.getErrors();
      this.isError = true;
    }, */
    isErrorField(field) {
      try {
        if (this.getValidationField(field).$error) {
          return true;
        }
      } catch (error) {
        //continue
      }
      return this.errors.some(el => el.field === field);
    },
    getErrors() {
      let errors = [];
      for (const field of Object.keys(this.form)) {
        try {
          if (this.getValidationField(field).$error) {
            errors.push({ field: field, message: null });
          }
        } catch (error) {
          //continue
        }
      }
      return errors;
    },
    getFieldClasses(field) {
      return { "is-invalid": this.isErrorField(field) };
    },
    getCharactersLeft(field) {
      try {
        return (
          this.getValidationField(field).$params.maxLength.max -
          this.form[field].length
        );
      } catch (error) {
        return 0;
      }
    },
    getValidationField(field) {
      if (this.$v.form[field]) {
        return this.$v.form[field];
      }
      throw Error("No validation for field " + field);
    },
    onFieldBlur(field) {
      try {
        this.getValidationField(field).$touch();
        if (this.getValidationField(field).$error) {
          if (!this.errors.some(el => el.field === field)) {
            this.errors.push({ field: field, message: null });
          }
        } else {
          this.errors = this.errors.filter(el => el.field !== field);
        }
      } catch (error) {
        //continue
      }
    },
    reload() {
      window.location = "";
    },
    initShow() {
      console.log("[LoginForm] initShow");
      this.mfaRequired = false;
      this.mfaToken = "";
      this.isError = false;
    },
    handleWrapperClick() {
      console.log("handleWrapperClick");
      this.doClose();
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
        this.$loginForm.close();
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }        
  },
  validations: {
    form: {
      user: { required },
      pass: { required },
      code: { required }
    }
  }
};
</script>
<style>
.apple-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: relative;
  padding-left: 3rem;
}

.apple-logo {
  height: 24px;
  margin-right: 12px;
  min-width: 24px;
  width: 24px;
  position: absolute;
  left: calc(1rem - 2px);
}

.google-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: relative;
  padding-left: 3rem;
}

.google-logo {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  position: absolute;
  left: 1rem;
}

.divider-surface {
  background: #E3E5E8;
}

.divider-v-xs {
  height: 1px;
  width: 100%;
}
</style>
