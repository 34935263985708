<template>
    <div class="overlay" :class="{ 'is-active': active, 'is-visible': visible }">
        <div class="dialog" :style="style" :class="{ 'is--active': active, 'is--visible': visible }" @click.self="handleWrapperClick">
            <div class="dialog__inner">
              <button v-if="!options.modal" class="button--icon button--close" @click="handleCloseClick">
                <i class="svgicon svgicon--close">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
                    </svg>
                </i>
              </button>
              <header class="dialog__header">
                  <h3>{{ options.title }}</h3>
              </header>
              <div class="dialog__content" v-html="options.text">
              </div>
                  <div class="dialog__actions">
                      <button class="button--dense" @click.prevent="onCancel">{{ getContent('CONF_CANCLE', true) }}</button>
                      <button class="button--primary" @click.prevent="onOk">{{ getContent('CONF_OK', true) }}</button>
                  </div>
              </div>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from "vuex";
//import Store from "@/store";
//import axios from "axios";
//import qs from "qs";

//import { Api } from "@/api/";
import { ContentController } from "@/controller";

export default {
  name: "Dialog",
  data() {
    return {
      isError: false,
      errors: [],
      submitting: false,
      mfaRequired: false,
      mfaToken: "",
      form: {
        user: "",
        pass: "",
        code: "",
        logintype: "login",
        pid: 112
      },
      visible: false,
      active: false,
      loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
      options: {
        title: "",
        text: "",
        onCancel: null,
        onOk: null
      }
    };
  },
  computed: {
    style() {
      return this.active ? "display: block;" : "display: none";
    },
  },
  properties: {
    title: {
      type: String,
      default: ""
    },
    bodytext: {
      type: String,
      default: ""
    }
  },
  watch: {},
  mounted() {},

  methods: {
    initShow() {
      console.log("[Dialog] initShow");
    },
    handleWrapperClick() {
      if (!this.options.modal) {
        this.doCancel();
      }
    },
    handleCloseClick() {
      this.doCancel();
    },
    doCancel() {
      this.cancel();
    },
    onCancel() {
      this.cancel();
    },
    onOk() {
      this.ok();
    },
    cancel() {
      if (typeof this.options.onCancel == 'function') {
        this.options.onCancel();
      }
      this.$dialog.close();
    },
    ok() {
      if (typeof this.options.onOk == 'function') {
        this.options.onOk();
      }
      this.$dialog.close();
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    } 
  }
};
</script>
